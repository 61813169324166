/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, useLayoutEffect } from "react";
import { Form, Radio, Space, Switch, Typography } from "antd";
import cn from "classnames";
import { parseInt } from "lodash";

import { QuestionCircleFilled } from "@ant-design/icons";
import { useReduxState, useUATDisableForm } from "@ni/common/hooks";
import { FormValues, SliderRanges } from "@ni/common/types";
import {
  CustomFormWrapper,
  InputRangeSlider,
  InputRangeSlider8Dig,
  NetworkForm,
  PageItemLayoutElements,
  PageItemLayoutGroup,
  TooltipInfo,
} from "@ni/common/ui";
import { getFormValueFromProductValues } from "@ni/common/utils";
import { ProductApi } from "@ni/sdk/apis";
import { ChangeProductRequest, Product, ProductValue } from "@ni/sdk/models";

import styles from "./styles.module.scss";

const { Text } = Typography;

const productServiceApi = new ProductApi();

export const CardNumbering: FC = () => {
  const [form] = Form.useForm();
  const [product, setCurrentProduct] = useReduxState<Product>("currentProduct", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading", false);

  const isDisabled = useUATDisableForm();

  useLayoutEffect(() => {
    if (product?.id) {
      const binLength =
        getFormValueFromProductValues(product?.productValues as ProductValue[], "bin-length", "number") || 8;

      form.setFieldsValue({
        "nic-card-subtype-pan-bin":
          (getFormValueFromProductValues(product.productValues, "nic-card-subtype-pan-bin") as string) || "",
        "nic-prod-parms-ica-number":
          (getFormValueFromProductValues(product.productValues, "nic-prod-parms-ica-number") as string) || "",
        "use-test-bin": (getFormValueFromProductValues(product.productValues, "use-test-bin") as string) !== "false",
        "bin-length": binLength,
        sliderRanges: {
          "nic-card-subtype-pan-full-min":
            product.productValues?.find(
              (productValue: ProductValue) => productValue.fieldCode === "nic-card-subtype-pan-full-min",
            )?.value || (binLength === 6 ? "000000" : "0000"),
          "nic-card-subtype-pan-full-max":
            product.productValues?.find(
              (productValue: ProductValue) => productValue.fieldCode === "nic-card-subtype-pan-full-max",
            )?.value || (binLength === 6 ? "999999" : "9999"),
        },
        "nic-prod-ips": getFormValueFromProductValues(
          product?.productValues as ProductValue[],
          "nic-prod-ips",
          "string",
        ),
        "ica-number-mc": getFormValueFromProductValues(product.productValues, "ica-number-mc") || "",
        "rep-bin-visa": getFormValueFromProductValues(product.productValues, "rep-bin-visa") || "",
      });
    }
  }, [product.id, product.productValues, form]);

  const onSubmit = (values: FormValues) => {
    setIsLoading(true);

    const sliderRanges = { ...(values["sliderRanges"] as SliderRanges) };
    delete values["sliderRanges"];
    delete values["nic-pros-ips"];

    const collectedValues: FormValues = {
      ...values,
      "nic-card-subtype-pan-full-min": sliderRanges["nic-card-subtype-pan-full-min"] ?? "",
      "nic-card-subtype-pan-full-max": sliderRanges["nic-card-subtype-pan-full-max"] ?? "",
    };

    const productId = product.id ?? -1;
    const requestBody: ChangeProductRequest = {
      productValues: Object.keys(collectedValues).map(val => ({
        fieldCode: val,
        value: collectedValues[val],
      })) as ProductValue[],
    };

    productServiceApi
      .editProduct(requestBody, productId)
      .then(res => {
        setCurrentProduct(res.data);
      })
      .catch(() => setIsLoading(false))
      .finally(() => setIsLoading(false));
  };

  const cleanUpBin = () => {
    const values = form.getFieldsValue();

    const slides: SliderRanges = {
      "nic-card-subtype-pan-full-min": "00000000",
      "nic-card-subtype-pan-full-max": "99999999",
    };

    if (values["use-test-bin"]) {
      form.setFieldsValue({
        "nic-card-subtype-pan-bin": null,
        sliderRanges: slides,
        "bin-length": 8,
      });
    }
  };

  const cleanUpBinDigits = () => {
    const values = form.getFieldsValue();

    const slides: SliderRanges = {
      "nic-card-subtype-pan-full-min": values["bin-length"] === 6 ? "0000000000" : "00000000",
      "nic-card-subtype-pan-full-max": values["bin-length"] === 6 ? "9999999999" : "99999999",
    };

    form.setFieldsValue({
      "nic-card-subtype-pan-bin": null,
      sliderRanges: slides,
    });
  };

  const checkRanges = (
    _: unknown,
    value: { "nic-card-subtype-pan-full-min": string; "nic-card-subtype-pan-full-max": string },
  ) => {
    const values = form.getFieldsValue();
    const minRange = value["nic-card-subtype-pan-full-min"];
    const maxRange = value["nic-card-subtype-pan-full-max"];

    if (
      minRange.length < (values["bin-length"] === 6 ? 10 : 8) ||
      maxRange.length < (values["bin-length"] === 6 ? 10 : 8)
    ) {
      return Promise.reject(new Error(`${values["bin-length"] === 6 ? 10 : 8} digits are required!`));
    }

    if (parseInt(minRange) > parseInt(maxRange)) {
      return Promise.reject(new Error("Min value should be lower than Max value!"));
    }

    if (parseInt(minRange) === parseInt(maxRange)) {
      return Promise.reject(new Error("Min value and Max value can't be the same"));
    }

    return Promise.resolve();
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Card Numbering"
      submitHandler={onSubmit}
      onValuesChange={form.validateFields}
      size="full"
      formSize="full"
      submitLabel="Save"
    >
      <PageItemLayoutGroup className={styles["details-form"]}>
        <PageItemLayoutElements>
          <div className="text-blue-h3">
            <TooltipInfo
              label="Bank Identification number (BIN)"
              tooltipProps={{
                title:
                  "Each product is associated with BIN (Bank Identification Number). BIN's will be provided by payment schemes. PAN generation is based on BIN.",
              }}
            />
          </div>
        </PageItemLayoutElements>
        <Space direction="horizontal">
          <Form.Item
            name="use-test-bin"
            valuePropName="checked"
            rules={[{ required: true, message: "Pin Set is required!" }]}
          >
            <Switch onChange={cleanUpBin} />
          </Form.Item>
          Use Network test BIN if you haven&apos;t got the real one
        </Space>
        <Form.Item noStyle={true} dependencies={["use-test-bin"]}>
          {() => (
            <Form.Item
              name="bin-length"
              label="What is the length of product BIN?"
              className="wizard-input input-group-label"
              rules={[
                { required: !form.getFieldValue("use-test-bin") && !isDisabled, message: "BIN Length is required." },
              ]}
            >
              <Radio.Group disabled={!!form.getFieldValue("use-test-bin") || isDisabled} onChange={cleanUpBinDigits}>
                <Space direction="vertical">
                  <Radio value={6}>6 digit</Radio>
                  <Radio value={8}>8 digit (default)</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          )}
        </Form.Item>
        <div>
          <Form.Item noStyle={true} dependencies={["use-test-bin", "bin-length"]}>
            {() => {
              const binLength = form.getFieldValue("bin-length") === 6 ? /^[0-9]{6}$/ : /^[0-9]{8}$/;
              return (
                <Form.Item
                  name="nic-card-subtype-pan-bin"
                  label="BIN"
                  rules={[
                    { required: !form.getFieldValue("use-test-bin") && !isDisabled, message: "BIN is required." },
                    {
                      pattern: form.getFieldValue("use-test-bin") || isDisabled ? /.*/ : binLength,
                      message: `BIN value must have ${form.getFieldValue("bin-length") as string} digits.`,
                    },
                  ]}
                >
                  <NetworkForm.Number
                    placeholder="BIN"
                    disabled={!!form.getFieldValue("use-test-bin") || isDisabled}
                    formatter={undefined}
                    parser={undefined}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item noStyle={true} dependencies={["bin-length"]}>
            {() => (
              <Text className="input-info">
                {`The first ${form.getFieldValue("bin-length") as string} digits of the card number for the product.`}
              </Text>
            )}
          </Form.Item>
        </div>
        <Form.Item dependencies={["nic-prod-ips"]}>
          {() =>
            form.getFieldValue("nic-prod-ips") === "Visa" ? (
              <Form.Item
                name="rep-bin-visa"
                label="Member ID"
                className="wizard-input"
                rules={[
                  {
                    pattern: /^[0-9]{1,6}$/,
                    message: `Member ID must have maximum 6 digits.`,
                  },
                  {
                    required: true,
                    message: "Member ID is required.",
                  },
                ]}
                tooltip={{
                  title:
                    "Center Processing BIN (Reporting BIN) assigned by Visa to a financial institution, third party processor, or other member to identify the member in transactions",
                  icon: <QuestionCircleFilled />,
                }}
              >
                <NetworkForm.Number placeholder="Member ID" formatter={undefined} parser={undefined} />
              </Form.Item>
            ) : (
              form.getFieldValue("nic-prod-ips") === "MC" && (
                <Form.Item
                  name="ica-number-mc"
                  label="ICA number"
                  className="wizard-input"
                  rules={[
                    { required: true, message: "ICA number is required." },
                    {
                      pattern: /^[0-9]{1,6}$/,
                      message: `ICA number must have maximum 6 digits.`,
                    },
                  ]}
                  tooltip={{
                    title:
                      "ICA (Interbank Card Association Number) assigned by MasterCard to a financial institution, third party processor, or other member to identify the member in transactions",
                    icon: <QuestionCircleFilled />,
                  }}
                >
                  <NetworkForm.String placeholder="ICA number" />
                </Form.Item>
              )
            )
          }
        </Form.Item>
      </PageItemLayoutGroup>
      <PageItemLayoutGroup className={styles["details-form"]}>
        <PageItemLayoutElements>
          <div className={cn("text-blue-h3", styles["details-form-tooltip-text-wrapper"])}>
            BIN subranges
            <Form.Item noStyle={true} dependencies={["bin-length"]}>
              {() => (
                <TooltipInfo
                  tooltipProps={{
                    title: (
                      <>
                        <p>
                          Card number (PAN) is 16 digit number which comprises of
                          {form.getFieldValue("bin-length") === 6
                            ? " 6 digit BIN + 10 digits "
                            : " 8 digit BIN + 8 digits "}
                          of allowed range by payment scheme. Issuer can choose PAN range based on allowed range by
                          payment scheme. This parameters together with BIN specify the minimum and maximum number in
                          allowed range for the product in generation of PAN.
                        </p>
                        <p>
                          It is recommend selecting a sub-range that would allow multiple product launches under the
                          same BIN and not launching a product across the whole BIN range.
                        </p>
                      </>
                    ),
                  }}
                />
              )}
            </Form.Item>
          </div>
        </PageItemLayoutElements>

        <Form.Item noStyle={true} dependencies={["nic-card-subtype-pan-bin", "bin-length"]}>
          {() => (
            <Form.Item
              name="sliderRanges"
              className="wizard-input"
              rules={[
                {
                  validator: checkRanges,
                },
              ]}
            >
              {form.getFieldValue("bin-length") === 6 ? (
                <InputRangeSlider form={form} disabled={isDisabled} />
              ) : (
                <InputRangeSlider8Dig form={form} disabled={isDisabled} />
              )}
            </Form.Item>
          )}
        </Form.Item>
      </PageItemLayoutGroup>
    </CustomFormWrapper>
  );
};
