import { FC, ReactNode, useCallback, useEffect, useMemo } from "react";
import { Col, Form, FormInstance, Row } from "antd";
import { Rule } from "antd/es/form";
import classNames from "classnames";

import { FeeField, FeeFieldType, FormValues } from "@ni/common/types";

import { NetworkForm } from "../FormInput";
import { TooltipInfo } from "../TooltipInfo";

import styles from "./styles.module.scss";

interface FeesValuesRowProps {
  rowLabel?: ReactNode;
  rowTooltip?: string;
  productCurrency: string;
  form: FormInstance<FormValues>;
  fields: FeeField[];
}

const getField = (key: FeeFieldType, fields: FeeField[]) => fields.find(field => field.type === key);

export const FeesValuesRow: FC<FeesValuesRowProps> = ({ rowLabel, rowTooltip, productCurrency, form, fields }) => {
  const percentageValue = Form.useWatch<string>(getField("percentage", fields)?.name ?? "", form);
  const isFeeAvailable = percentageValue !== "" && percentageValue !== undefined && percentageValue !== null;
  const minFieldName = useMemo(() => getField("min", fields)?.name ?? "", [fields]);
  const maxnFieldName = useMemo(() => getField("max", fields)?.name ?? "", [fields]);

  useEffect(() => {
    if (!isFeeAvailable && !form.getFieldValue(getField("percentage", fields)?.name))
      form.resetFields([minFieldName, maxnFieldName]);
  }, [fields, form, isFeeAvailable, maxnFieldName, minFieldName]);

  const inputRules = useCallback(
    (type: string) => {
      return type === "max"
        ? ([
            {
              type: "number",
              validator: (_, value) => {
                const minimumAmount = +form.getFieldValue(getField("min", fields)?.name ?? "") as unknown as number;
                if (value != null && !Number.isNaN(minimumAmount)) {
                  return minimumAmount <= Number(value) ? Promise.resolve() : Promise.reject();
                }
                return Promise.resolve();
              },
              message: "Maximum amount cannot be lower than Minimum amount",
            },
            {
              validator: (_, value) => (value != null && Number(value) === 0 ? Promise.reject() : Promise.resolve()),
              message: "Maximum fee amount can not be 0",
            },
          ] as Rule[])
        : undefined;
    },
    [fields, form],
  );

  return (
    <Row className={styles["fees-row-inputs-wrapper"]}>
      <Col span={4}>
        <div className={classNames("ant-form-vertical ant-form-item-label", styles["first-row-cell"])}>
          {rowTooltip ? (
            <TooltipInfo label={rowLabel as string} tooltipProps={{ title: rowTooltip, className: "" }} />
          ) : (
            rowLabel
          )}
        </div>
      </Col>

      <Col span={20}>
        <Row className={styles["fees-row-inputs-wrapper"]}>
          {fields.map(field => {
            return (
              <Col key={field.name} span={Math.round(24 / fields.length)} className={styles["fees-row-input"]}>
                {field?.dependencies && field?.dependencies.length > 0 ? (
                  <Form.Item name={field.name} dependencies={field.dependencies}>
                    {() => (
                      <Form.Item name={field.name} rules={inputRules(field.type)}>
                        <NetworkForm.Number
                          min={0}
                          precision={2}
                          addonAfter={field.type === "percentage" ? "%" : productCurrency}
                          disabled={(field.type === "min" || field.type === "max") && !isFeeAvailable}
                          stringMode={true}
                          {...field.props}
                        />
                      </Form.Item>
                    )}
                  </Form.Item>
                ) : (
                  <Form.Item name={field.name} rules={inputRules(field.type)}>
                    <NetworkForm.Number
                      min={0}
                      precision={2}
                      addonAfter={field.type === "percentage" ? "%" : productCurrency}
                      disabled={(field.type === "min" || field.type === "max") && !isFeeAvailable}
                      stringMode={true}
                      {...field.props}
                    />
                  </Form.Item>
                )}
              </Col>
            );
          })}
        </Row>
      </Col>
    </Row>
  );
};
